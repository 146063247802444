import { Component, inject, input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Campaign, Target, Volunteer } from '../types';
import { TargetService } from '../target.service';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-script',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgbDropdownModule],
  templateUrl: './script.component.html',
  styleUrl: './script.component.css'
})
export class ScriptComponent {
  volunteer = input.required<Volunteer>();
  campaign = input.required<Campaign>();

  private targetService: TargetService = inject(TargetService);

  public hasTarget: boolean = false;
  public target: Target | undefined = undefined;

  public contactResponses: string[] = ['Yes', 'Voicemail', 'Other language', 'Busy or not home', 'Hangup', 'Moved', 'Deceased', 'Wrong number', 'Out of service', 'Refused or hostile'];
  public askResponses: string[] = ['Yes', 'Maybe', 'No'];

  // Keep in sync with types.ts
  responseForm = new FormGroup({
    campaign: new FormControl(''),
    id: new FormControl(''),
    firstname: new FormControl(''),
    lastname: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
    status: new FormControl(''),
    timestamp_millis: new FormControl(0),
    volunteer_firstname: new FormControl(''),
    volunteer_lastname: new FormControl(''),
    volunteer_phone: new FormControl(''),
    volunteer_email: new FormControl(''),
    contact_response: new FormControl(''),
    ask_response: new FormControl(''),
  });

  constructor() {
  }

  ngOnInit(): void {
    this.onLoad();
  }

  onLoad() {
    this.targetService.getTarget(this.campaign().id || "").then((target: Target | undefined) => {
      if (target) {
        this.responseForm.reset();
        this.hasTarget = true;
        this.responseForm.patchValue(target);
        this.target = target;
        const volunteer = this.volunteer();
        this.responseForm.patchValue({
          volunteer_firstname: volunteer.firstname,
          volunteer_lastname: volunteer.lastname,
          volunteer_phone: volunteer.phone,
          volunteer_email: volunteer.email
        });
      } else {
        this.hasTarget = false;
      }
    });
  }

  async onSubmit() {
    let target = this.responseForm.value as Target;
    target.status = "done";
    await this.targetService.setTarget(target);
    this.onLoad();
  }
}
