export const environment = {
    firebase: {
        "apiKey": "AIzaSyAJ1OgG56bBzptNWVfWlsdev_8GixN1gkU",
        "authDomain": "votebluenyc-prod.firebaseapp.com",
        "projectId": "votebluenyc-prod",
        "storageBucket": "votebluenyc-prod.appspot.com",
        "messagingSenderId": "609207736980",
        "appId": "1:609207736980:web:633ec16bae492a59e77281"
    },
};
