import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Campaign, Volunteer } from '../types';
import { ScriptComponent } from '../script/script.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CampaignService } from '../campaign.service';

@Component({
  selector: 'app-campaign',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ScriptComponent, NgbCollapseModule],
  templateUrl: './campaign.component.html',
  styleUrl: './campaign.component.css'
})
export class CampaignComponent {
  private campaignService: CampaignService = inject(CampaignService);

  toggleNavbar = true;

  trySubmit: boolean = false;
  // Keep in sync with types.ts
  userForm = new FormGroup({
    campaignSelector: new FormControl(''),
    firstname: new FormControl(''),
    lastname: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
  });
  volunteer: Volunteer | undefined = undefined;
  campaign: Campaign | undefined = undefined;
  campaigns: Campaign[] = [];

  get userFormControl() {
    return this.userForm.controls;
  }

  constructor() {
  }

  async ngOnInit(): Promise<void> {
    this.campaigns = await this.campaignService.getAllCampaigns();
    this.userForm.controls.campaignSelector.setValue(this.campaigns[0].id);
  }

  onSubmit() {
    this.trySubmit = true;
    if (this.userForm.invalid) {
      return;
    }
    this.campaign = this.campaigns.find(c => c.id === this.userForm.value.campaignSelector) as Campaign;
    this.volunteer = this.userForm.value as Volunteer;
  }

  logout() {
    this.trySubmit = false;
    this.userForm.reset();
    this.volunteer = undefined;
  }
}
