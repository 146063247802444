import { Injectable, inject } from '@angular/core';
import { Firestore, DocumentReference, collection, query, where, doc, setDoc, getDocs, limit, or, and } from '@angular/fire/firestore';
import { Target } from './types'

@Injectable({
  providedIn: 'root'
})
export class TargetService {
  private firestore: Firestore = inject(Firestore);
  private targetCollection: string = "/Phonebank/targets/targets/";

  constructor() { }

  async getTarget(campaignId: string): Promise<Target | undefined> {
    const targetsRef = collection(this.firestore, this.targetCollection);
    const timeLimit = Date.now() - 1000 * 60 * 5; // 5 minutes ago
    if (!campaignId) {
      return Promise.resolve(undefined);
    }

    // Query 50 new targets from the DB.
    const q = query(targetsRef,
      and(where("campaign", "==", campaignId),
        or(where("status", "==", "new"),
          and(where("status", "==", "locked"),
            where("timestamp_millis", "<", timeLimit)))),
      limit(50)
    );
    return getDocs(q).then((querySnapshot) => {
      if (querySnapshot.size > 0) {
        // Pick one at random.
        const i: number = Math.floor(Math.random() * querySnapshot.size);
        const doc = querySnapshot.docs[i];
        return doc.data() as Target;
      };
      return undefined;
    }).then((target: Target | undefined) => {
      if (target) {
        this.lockTarget(target);
      }
      return target;
    });
  }

  lockTarget(target: Target): Promise<void> {
    target.status = "locked";
    return this.setTarget(target);
  }

  setTarget(target: Target): Promise<void> {
    const docRef: DocumentReference = doc(this.firestore, `${this.targetCollection}${target.id}`);
    target.timestamp_millis = Date.now();
    return setDoc(docRef, target);
  }
}
