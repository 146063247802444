<div>
  <div class="card col-xl">
    <div *ngIf="hasTarget; else noTarget">
      <form [formGroup]="responseForm" (ngSubmit)="onSubmit()">
        <div class="card-header">
          <h4>
            {{ responseForm.controls["firstname"].value }}
            {{ responseForm.controls["lastname"].value }}:
            {{ responseForm.controls["phone"].value }}
          </h4>
        </div>
        <div class="card-body">
          <div class="row py-2">
            <p>
              Hello, this is {{ volunteer().firstname }}. Is
              {{ responseForm.controls["firstname"].value }} there please?
            </p>
          </div>
          <div class="row py-2">
            <div ngbDropdown class="d-inline-block">
              <button
                type="button"
                class="btn btn-outline-primary"
                id="contactResponseLabel"
                ngbDropdownToggle
              >
                {{
                  responseForm.controls["contact_response"].value
                    ? responseForm.controls["contact_response"].value
                    : "Were you able to contact this person?"
                }}
              </button>
              <div ngbDropdownMenu aria-labelledby="contactResponseLabel">
                <a
                  class="dropdown-item"
                  *ngFor="let item of contactResponses"
                  (click)="
                    responseForm.controls['contact_response'].setValue(item)
                  "
                >
                  {{ item }}
                </a>
              </div>
            </div>
          </div>
          <div *ngIf="responseForm.controls['contact_response'].value == 'Yes'">
            <div class="row py-2">
              <p>
                {{ campaign().live_script }}
              </p>
            </div>
            <div class="row py-2">
              <div ngbDropdown class="d-inline-block">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  id="askResponseLabel"
                  ngbDropdownToggle
                >
                  {{
                    responseForm.controls["ask_response"].value
                      ? responseForm.controls["ask_response"].value
                      : "How did they respond?"
                  }}
                </button>
                <div ngbDropdownMenu aria-labelledby="askResponseLabel">
                  <a
                    class="dropdown-item"
                    *ngFor="let item of askResponses"
                    (click)="
                      responseForm.controls['ask_response'].setValue(item)
                    "
                  >
                    {{ item }}
                  </a>
                </div>
              </div>
            </div>
            <div class="row py-2">
              <label for="firstname" class="form-label">Voter email</label>
              <input
                type="text"
                class="form-control"
                id="email"
                formControlName="email"
              />
            </div>
          </div>
          <div
            *ngIf="
              responseForm.controls['contact_response'].value == 'Voicemail'
            "
          >
            <div class="row py-2">
              <p>
                {{ campaign().voicemail_script }}
              </p>
            </div>
          </div>
          <div class="row py-2">
            <p>Thanks so much for your time!</p>
          </div>
          <div class="row py-2">
            <button
              type="submit"
              [disabled]="!responseForm.valid"
              class="btn btn-primary"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <ng-template #noTarget>
    <div class="card-body">
      <h3 class="card-title">{{ campaign().name }}</h3>
      <p>This phonebank is complete. We're out of people to call!</p>
    </div>
  </ng-template>
</div>
